import React, { useState } from "react";
import "antd/dist/antd.css";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

function Header() {
  const { SubMenu } = Menu;
  const [current, setCurrent] = useState("home");
  const handleClick = (e) => {
    setCurrent(e.key);
  };

  return (
    <Menu
      theme="dark"
      onClick={handleClick}
      selectedKeys={[current]}
      mode="horizontal"
      style={{
        position: "fixed",
        left: "0px",
        top: "0px",
        width: "100%",
        zIndex: 1,
      }}
    >
      <Menu.Item key="logo">
        {" "}
        <img src={logo} style={{ height: 30, width: 50 }} alt="MVA" />
      </Menu.Item>
      <Menu.Item key="home">
        <Link to="/">Home</Link>
      </Menu.Item>
      {/* <Menu.Item key="about">
                <Link to="/about">About</Link>
            </Menu.Item> */}
      <Menu.Item key="media">
        <Link to="/media">Media</Link>
      </Menu.Item>
      <SubMenu key="collection" title="Collection">
        {/* <Menu.Item key="artist">
          <Link to="/artist">Artist</Link>
          Artist
        </Menu.Item> */}
        <Menu.Item key="painting">
          <Link to="/painting">Painting</Link>
        </Menu.Item>
        {/* <Menu.Item key="sculpture">
          <Link to="/sculpture">Sculpture</Link>
          Sculpture
        </Menu.Item> */}
      </SubMenu>
      <Menu.Item key="contact">
        <Link to="/contact">Contact Us</Link>
      </Menu.Item>
    </Menu>
  );
}

export default Header;
