import { Switch, Route } from "react-router-dom";
import About from "../pages/about";
import Home from "../pages/home";
import Contact from "../pages/contact";
import Exhibition from "../pages/exhibition";
import Artist from "../pages/artist";
import Painting from "../pages/painting";
import Sculpture from "../pages/sculpture";
import Media from '../pages/media';

export const AppRoutes = () => {
    return(
        <div>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/exhibition">
            <Exhibition/>
          </Route>
          <Route path="/about">
            <About/>
          </Route>
          <Route path="/artist">
            <Artist/>
          </Route>
          <Route path="/media">
            <Media/>
          </Route>
          <Route path="/painting">
            <Painting />
          </Route>
          <Route path="/sculpture">
            <Sculpture/>
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
        </Switch>
      </div>
    )
}