/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import "../styles/contact.css"
import a from '../assets/12.jpeg'

function Contact() {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexWrap: 'wrap', paddingTop: 150, paddingBottom: 40 }}>

            <div className="contact-image-container">
                <img src={a} className="contact-image" alt="MVA" />

            </div>
            <div>
                <h2 style={{ fontWeight: 'bold' }}>Meghvarna Art Gallery</h2>
                <div>
                    <h3> <span style={{ fontWeight: 'bold' }}>Address</span> : C-122, Noida, Pincode : 201301</h3>
                    <h3> <span style={{ fontWeight: 'bold' }}>Telephone</span> : 8527675374</h3>
                    <h3> <span style={{ fontWeight: 'bold' }}>Email</span> : meghvarnaartgallery@gmail.com</h3>
                    <h3><span style={{ fontWeight: 'bold' }}>Timings</span> : Monday - Saturday | 10 AM - 5 PM</h3>
                </div>
            </div>
            {/* <div style={{ marginBottom: 40 }} >

                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfnU-D0yQgo2aGtad5h8vH3jhubI_w0NUsbUU7hcOLH1ODNOw/viewform?embedded=true" scrolling="no" width="700" height="840" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: 800 }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px solid #000000', height: 420, width: 420, marginTop: -100, marginBottom: 20 }}>
                    <img src={a} style={{ height: 400, width: 400 }} alt="MVA" />

                </div>

                <h2 style={{ fontWeight: 'bold' }}>Meghvarna Art Gallery</h2>
                <div>
                    <h3> <span style={{ fontWeight: 'bold' }}>Address</span> : C-122, Noida, Pincode : 201301</h3>
                    <h3> <span style={{ fontWeight: 'bold' }}>Telephone</span> : 8802832621</h3>
                    <h3> <span style={{ fontWeight: 'bold' }}>Email</span> : meghvarnaartgallery@gmail.com</h3>
                    <h3><span style={{ fontWeight: 'bold' }}>Timings</span> : Monday - Saturday | 10 AM - 5 PM</h3>
                </div>
            </div> */}

        </div>
    )
}

export default Contact;