import React, { useState } from 'react';
import Gallery from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { PAINTINGIMAGES } from '../utils/images';

function Painting() {
   
    const [index, setIndex] = useState(-1);

    const currentImage = PAINTINGIMAGES[index];
    const nextIndex = (index + 1) % PAINTINGIMAGES.length;
    const nextImage = PAINTINGIMAGES[nextIndex] || currentImage;
    const prevIndex = (index + PAINTINGIMAGES.length - 1) % PAINTINGIMAGES.length;
    const prevImage = PAINTINGIMAGES[prevIndex] || currentImage;
  
    const handleClick = (index, item) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    return (
        <div>
        <h2 style={{ marginTop: 70, marginLeft: 40 }}>Paintings</h2>
        <div style={{
            marginLeft: 60,
            marginRight: 60,
            marginTop: 20,
            display: "block",
            minHeight: "1px",
            border: "1px solid #ddd",
            overflow: "auto",
          }}>
          <Gallery
            rowHeight={350}
            margin={7}
            images={PAINTINGIMAGES}
            onClick={handleClick}
            enableImageSelection={false}
          />
          {!!currentImage && (
            <Lightbox
              mainSrc={currentImage.original}
              imageTitle={currentImage.caption}
              mainSrcThumbnail={currentImage.src}
              nextSrc={nextImage.original}
              nextSrcThumbnail={nextImage.src}
              prevSrc={prevImage.original}
              prevSrcThumbnail={prevImage.src}
              onCloseRequest={handleClose}
              onMovePrevRequest={handleMovePrev}
              onMoveNextRequest={handleMoveNext}
            />
          )}
        </div>
        </div>
      );

}

export default Painting;