import React from "react";
import "antd/dist/antd.css";
import { Menu } from "antd";
import { FaInstagram, FaFacebook } from "react-icons/fa";

function Footer() {
  return (
    <Menu
      theme="dark"
      mode="horizontal"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        left: "0px",
        bottom: "0px",
        width: "100%",
        zIndex: 1,
      }}
    >
      <div style={{ marginRight: 20 }}> © 2021 Meghvarna Art Gallery </div>
      <a
        href="https://instagram.com/meghvarna_art_gallery/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginRight: 20 }}
      >
        <FaInstagram />
      </a>
      <a
        href="https://m.facebook.com/Meghvarna-Art-Gallery-104595535319592/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebook />
      </a>
    </Menu>
  );
}

export default Footer;
