import React from 'react';
import 'antd/dist/antd.css';
import { Carousel } from 'antd';
import "../styles/home.css";

import a from '../assets/1.jpeg';
import b from '../assets/2.jpeg';
import c from '../assets/3.jpeg';
import d from '../assets/4.jpeg';
import f from '../assets/6.jpeg';
import g from '../assets/7.jpeg';
import i from '../assets/9.jpeg';
import j from '../assets/10.jpeg';
import k from '../assets/22.jpg';
import l from '../assets/23.jpg';
import m from '../assets/28.jpg';
import n from '../assets/29.jpg';
import ll from '../assets/40.jpg';
import pp from '../assets/41.JPG'

const contentStyle = {
    objectfit: 'fill',
};


function Home() {
    return (
        <>
            <div className="home-main">

                <div className="home-container">


                    <Carousel autoplay effect="fade" className='home-corousal'>
                    <img style={contentStyle} src={m} alt="MVA" />
                        <img style={contentStyle} src={a} alt="MVA" />
                        <img style={contentStyle} src={b} alt="MVA" />
                        <img style={contentStyle} src={c} alt="MVA" />
                        <img style={contentStyle} src={d} alt="MVA" />
                        <img style={contentStyle} src={k} alt="MVA" />
                        <img style={contentStyle} src={l} alt="MVA" />
                        
                        <img style={contentStyle} src={n} alt="MVA" />

                    </Carousel>
                </div>
                <h1 className="home-heading-1">About Meghvarna Art Gallery</h1>
                <h2 className="home-para">   कलानां प्रवरं चित्रम् धर्मार्थ काम मोक्षादं।
                    मांगल्य प्रथम् दोतद् गृहे यत्र प्रतिष्ठितम् ॥</h2>
                <h3 className="home-para">
                    Meghvarna Art gallery started with vision and deep interest of Rachna Rana, An artist from Uttar Pradesh. She completed her M.Phil in Visual Arts from Banasthali University Rajasthan. Her inclination towards visual art and affection towards colors starts and deepen along with her education where she absorbed accute attention associated with Indian Modern Art. Subsquently she refined her art that emerged through various stages between color, lines and aesthetic. She is firm believer that as we appreciate and enjoy “ Art of living” we must acknowledge and celebrate “Art for living” . With same connection the journey of Meghvarna Art gallery started with immense committment to promote, preserve and appraisal of Modern and contemprory Indian art. It believes in it’s motto of “ Art for living” and seeks to promote Indian art at every corner of world.
                </h3>

                <h1 className='home-heading-1'>Our Guiding Lights</h1>

                <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', marginRight: 100, marginLeft: 100, marginBottom: 50 }}>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginBottom: 30 }} >
                        <img src={f} style={{ width: 350, height: 400, marginBottom: 10 }} alt="MVA" />
                        <p>Late Kedarnath Singh (Eminent Poet and GyanPeeth Laureate)</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginBottom: 30 }} >
                        <img src={ll} style={{ width: 350, height: 400, marginBottom: 10 }} alt="MVA" />
                        <p>R.N. Singh (Founder of Intl. Renowned-"Progressive Art Gallery")</p>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginBottom: 30 }} >
                        <img src={i} style={{ marginBottom: 10, width: 350, height: 400 }} alt="MVA" />
                        <p>Sanjjeev K. Singh (Founder ASAR Digital USA)</p>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginBottom: 30 }} >
                        <img src={pp} style={{ marginBottom: 10, width: 350, height: 400 }} alt="MVA" />
                        <p>Shri M.K. Yadav(Founder of "the IAS hub")</p>
                    </div>



                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginBottom: 30 }} >
                        <img src={g} style={{ marginBottom: 10, width: 350, height: 400 }} alt="MVA" />
                        <p>Shri Dharmendra Rathore (Eminent Artist)</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginBottom: 30 }} >
                        <img src={j} style={{ marginBottom: 10, width: 350, height: 400 }} alt="MVA" />
                        <p>Shri Arvind Kumar Singh (Eminent Writer and Journalist)</p>
                    </div>




                </div>
            </div>

        </>
    )
}

export default Home;