import React from "react";
import Gallery from "react-grid-gallery";
import { MEDIAIMAGES } from "../utils/images";

function Media() {
  return (
    <div>
      <h2 style={{ marginTop: 70, marginLeft: 40 }}>Media Coverage</h2>
      <div
        style={{
          marginLeft: 60,
          marginRight: 60,
          marginTop: 20,
          display: "block",
          minHeight: "1px",
          border: "1px solid #ddd",
          overflow: "auto",
        }}
      >
        <Gallery
          rowHeight={250}
          margin={7}
          images={MEDIAIMAGES}
          enableImageSelection={false}
        />
      </div>
    </div>
  );
}

export default Media;
