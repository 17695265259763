import './App.css';
import Header from './components/header';
import Footer  from './components/footer';
import { AppRoutes } from './components/router';
import { AppProvider } from './components/provider';

function App() {
  return (
    <AppProvider>
      <Header/>
      <AppRoutes/>
      <Footer/>
    </AppProvider>
  );
}

export default App;
