import a from "../assets/13.jpeg";
import b from "../assets/14.jpeg";
import c from "../assets/15.jpeg";
import d from "../assets/16.jpeg";
import e from "../assets/17.jpeg";
import g from "../assets/19.jpeg";
import h from "../assets/20.jpeg";
import i from "../assets/21.jpeg";
import j from "../assets/24.jpg";
import k from "../assets/25.jpg";
import l from "../assets/26.jpg";
import m from "../assets/27.jpeg";
import aa from "../assets/30.jpg";
import bb from "../assets/31.jpg";
import cc from "../assets/32.jpg";
import dd from "../assets/33.jpg";
import ee from "../assets/34.jpg";
import ff from "../assets/35.jpg";
import gg from "../assets/36.jpg";
import hh from "../assets/37.jpg";
import jj from "../assets/38.jpg";
import kk from "../assets/39.jpg";
import m1 from "../assets/media (1).jpeg";
import m2 from "../assets/media (2).jpeg";
import m3 from "../assets/media (3).jpeg";
import m5 from "../assets/media (5).jpeg";
import m6 from "../assets/media (6).jpeg";
import m7 from "../assets/media (7).jpeg";
import m8 from "../assets/media (8).jpeg";
import m9 from "../assets/media (9).jpeg";
import m10 from "../assets/media (10).jpeg";
import m11 from "../assets/media (11).jpeg";
import m12 from "../assets/media (12).jpeg";
import m13 from "../assets/media (13).jpeg";
import m14 from "../assets/media (14).jpeg";
import m15 from "../assets/media (15).jpeg";
import m16 from "../assets/media (16).jpeg";
import P1 from "../assets/Painting (1).jpeg";
import P2 from "../assets/Painting (2).jpeg";
import P3 from "../assets/Painting (3).jpeg";
import P4 from "../assets/Painting (4).jpeg";
import P5 from "../assets/Painting (5).jpeg";
import P6 from "../assets/Painting (6).jpeg";
import P7 from "../assets/Painting (7).jpeg";
import P8 from "../assets/Painting (8).jpeg";
import P9 from "../assets/Painting (9).jpeg";
import P10 from "../assets/Painting (10).jpeg";
import P11 from "../assets/Painting (11).jpeg";
import P12 from "../assets/Painting (12).jpeg";
import P13 from "../assets/Painting (13).jpeg";
import P14 from "../assets/Painting (14).jpeg";
import P15 from "../assets/Painting (15).jpeg";
import P16 from "../assets/Painting (16).jpeg";
import P17 from "../assets/Painting (17).jpeg";
import P18 from "../assets/Painting (18).jpeg";
import P19 from "../assets/Painting (19).jpeg";
import P20 from "../assets/Painting (20).jpeg";
import P21 from "../assets/Painting (21).jpeg";

export const MEDIAIMAGES = [
  {
    src: m15,
    thumbnail: m15,
    thumbnailWidth: 300,
    thumbnailHeight: 174,
    caption:
      "The Founder of Meghvarna Art Gallery Smt. Rachna Rana is awarded with Kala Ratna - 2024 at Crown Plaza Delhi by BPA Foundation and India netbooks.",
  },
  {
    src: m16,
    thumbnail: m16,
    thumbnailWidth: 300,
    thumbnailHeight: 174,
    caption:
      "Founder of Meghvarna Art Gallery shared her thought about Indian Art at Pink Fest Jaipur in presence of many respected Artists, thinkers and educationist.",
  },
  {
    src: m1,
    thumbnail: m1,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption: "",
  },
  {
    src: m2,
    thumbnail: m2,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption: "",
  },
  {
    src: m3,
    thumbnail: m3,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption: "",
  },
  {
    src: m5,
    thumbnail: m5,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption: "",
  },
  {
    src: m6,
    thumbnail: m6,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption: "",
  },
  {
    src: m7,
    thumbnail: m7,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption: "",
  },
  {
    src: m8,
    thumbnail: m8,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption: "",
  },
  {
    src: m9,
    thumbnail: m9,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption: "",
  },
  {
    src: m10,
    thumbnail: m10,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption: "",
  },
  {
    src: m11,
    thumbnail: m11,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption: "",
  },
  {
    src: m12,
    thumbnail: m12,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption: "",
  },
  {
    src: m13,
    thumbnail: m13,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption: "",
  },
  {
    src: m14,
    thumbnail: m14,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption: "",
  },
  {
    src: kk,
    thumbnail: kk,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption:
      "An artwork of Meghvarna Art gallery is presented to Shri D N Singh Former Drug Controller General of India and currently Advisor to CM of UP Shri Adityanath ji.",
  },
  {
    src: jj,
    thumbnail: jj,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption:
      "Artwork titled Shiv- Shakti by Founder of Meghvarna Art Gallery Smt. Rachna Rana is presented to an eminentIAS Shri D S Mishra , Chief Secretary UP Govt.",
  },
  {
    src: hh,
    thumbnail: hh,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption:
      "Smt Rachna Rana with MP Asansol and a legend Actor Shri Shatrughan Sinha",
  },
  {
    src: gg,
    thumbnail: gg,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption:
      "Artwork titled Shiv- Shakti by Founder of Meghvarna Art Gallery Shri Jaiveer Singh , Minister of Culture UP govt.",
  },
  {
    src: ff,
    thumbnail: ff,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption:
      "Founder of MAG with Shri Prateek Trivedi Senior Editor of News 18.",
  },
  {
    src: ee,
    thumbnail: ee,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption:
      "A Social initiative of MAG to support education of children from Shahdara and Mansarovar Park area.",
  },
  {
    src: dd,
    thumbnail: dd,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption:
      "Founder Meghvarna Art Gallery awarded at Constitution Club of India, Delhi by Padma Shree Smt Deepa Malik",
  },
  {
    src: cc,
    thumbnail: cc,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption:
      "An Artwork of Meghvarna Art Fallery is Presented to Member of Parliament Shri Satpal Chaudhary",
  },
  {
    src: bb,
    thumbnail: bb,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption:
      "An artwork from Meghvarna Art gallery founded by Rachna Rana is present to Hon'ble CM of Assam Shri Hemnta Sarma Biswas.",
  },
  {
    src: aa,
    thumbnail: aa,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption:
      "Artwork titled Shiv- Shakti by Founder of Meghvarna Art Gallery Smt. Rachna Rana is presented to Durga Shakti NAgpal , An IAS officer of UP Cadre and Special Secretary Health Education Govt of UP.",
  },
  {
    src: m,
    thumbnail: m,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption: "Media coverage of a programme and cultural show",
  },
  {
    src: k,
    thumbnail: k,
    thumbnailWidth: 320,
    thumbnailHeight: 200,
    caption: "Media coverage of a programme and cultural show",
  },
  {
    src: a,
    thumbnail: a,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Media coverage of Rang Malhar Rajasthan and Rachna Rana",
  },
  {
    src: l,
    thumbnail: l,
    thumbnailWidth: 320,
    thumbnailHeight: 300,
    caption: "Media coverage of a programme and cultural show",
  },
  {
    src: j,
    thumbnail: j,
    thumbnailWidth: 320,
    thumbnailHeight: 300,
    caption: "Media coverage of a programme and cultural show",
  },

  {
    src: b,
    thumbnail: b,
    thumbnailWidth: 320,
    thumbnailHeight: 374,
    caption:
      "Character tree award to Saurabh Singh during a programme and cultural show",
  },
  {
    src: c,
    thumbnail: c,
    thumbnailWidth: 320,
    thumbnailHeight: 474,
    caption: "Media coverage of a programme and cultural show",
  },
  {
    src: d,
    thumbnail: d,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "During an art exhibition by Jaguar motors with Ambassdor Morocco",
  },
  {
    src: i,
    thumbnail: i,
    thumbnailWidth: 320,
    thumbnailHeight: 474,
    caption: "Our mentor late Kedarnath Singh Eminent writer & poet",
  },
  {
    src: e,
    thumbnail: e,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption:
      "Presenting a portrait of Late Ramjethmalini Eminent Lawyer in presence of our mentor Ms. Maryam and Saurabh Singh",
  },
  {
    src: g,
    thumbnail: g,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "During an Art exhibition of Progressive art group artists",
  },
  {
    src: h,
    thumbnail: h,
    thumbnailWidth: 300,
    thumbnailHeight: 174,
    caption:
      "Rachna Rana with Eminent artist Shri Dharmendra Rathore and DG NGMA Shri Advaitya Gadnayak ",
  },

];

export const PAINTINGIMAGES = [
  {
    src: P1,
    thumbnail: P1,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P2,
    thumbnail: P2,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P3,
    thumbnail: P3,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P4,
    thumbnail: P4,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P5,
    thumbnail: P5,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P6,
    thumbnail: P6,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P7,
    thumbnail: P7,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P8,
    thumbnail: P8,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P9,
    thumbnail: P9,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P10,
    thumbnail: P10,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P11,
    thumbnail: P11,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P12,
    thumbnail: P12,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P13,
    thumbnail: P13,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P14,
    thumbnail: P14,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P15,
    thumbnail: P15,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P16,
    thumbnail: P16,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P17,
    thumbnail: P17,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P18,
    thumbnail: P18,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P19,
    thumbnail: P19,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P20,
    thumbnail: P20,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
  {
    src: P21,
    thumbnail: P21,
    thumbnailWidth: 5080,
    thumbnailHeight: 6000,
    caption: "",
  },
];
